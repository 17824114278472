<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("core$common", ["node"]),
  },
  created() {
    const defaultBoard = this.node?.settings?.boards?.default;
    if (!defaultBoard) return;
    this.$router.push({ name: "boards", params: { board: defaultBoard } });
  },
};
</script>
